// react
import React from "react";
import { useHistory } from "react-router-dom";
import { getNoImg, url } from "../../services/utils";

// application
import Currency from "./Currency";
import Point from "./Point";

function OrderTable(props) {
    const { order, from } = props;
    const history = useHistory();

    return order ? (
        <table className="order-details">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody className="card-table__body card-table__body--merge-rows">
                {order.order_details.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td className={item.additional?.is_pwp ? "pl-5" : ""}>
                                <span className="d-flex c-pointer" onClick={() => history.push(url.product(item.product_detail?.product))}>
                                    <span className="product-image">
                                        <img
                                            src={item.product_image && item.product_image[0] ? item.product_image[0]?.url : getNoImg()}
                                            onError={(e) => (e.target.src = getNoImg())}
                                            alt={item.product_detail?.product?.name}
                                        />
                                    </span>
                                    <span className="pl-1">
                                        {item.product_detail?.product?.name + " x " + item.quantity}
                                        <ul className="cart-table__options mt-0">
                                            <li>
                                                {item.product_detail?.variant_group?.combinations.length == 1 &&
                                                item.product_detail?.variant_group?.combinations[0]?.name == "main"
                                                    ? null // if no variant options
                                                    : item.product_detail?.variant_group?.combinations.map((opt, index) => {
                                                          return (
                                                              opt.name +
                                                              (item.product_detail?.variant_group?.combinations.length !== index + 1
                                                                  ? ", "
                                                                  : "")
                                                          );
                                                      })}
                                            </li>
                                            {item.addon_info?.length > 0
                                                ? item.addon_info?.map((addon, index) => {
                                                      return <li>{"+ " + addon.addon_item.additional_json.options[addon.index]?.name}</li>;
                                                  })
                                                : null}
                                        </ul>
                                    </span>
                                </span>
                            </td>
                            <td>
                                {item.additional?.reward_product_item ? (
                                    <Point value={item.additional?.reward_product_item.point} />
                                ) : item.additional?.event_cart_gift_item ? (
                                    <span className="outline-label">Free Gift</span>
                                ) : parseFloat(item.discount_amount) > 0 ? (
                                    <>
                                        <span className="product__old-price" style={{ fontSize: 13 }}>
                                            <Currency value={item.ori_total_price} />
                                        </span>
                                        <Currency value={item.total_price} />
                                    </>
                                ) : (
                                    <Currency value={item.total_price} />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
            <tbody className="card-table__body card-table__body--merge-rows total-summary">
                {order.additional?.total_redeem_prod_point ? (
                    <tr>
                        <th>Point Used</th>
                        <td className="text-danger">
                            <Point value={order.additional?.total_redeem_prod_point} />
                        </td>
                    </tr>
                ) : null}

                <tr>
                    <th>Subtotal</th>
                    <td>
                        <Currency
                            value={
                                order.ori_subtotal_amount -
                                (order?.discount_amount -
                                    Math.abs(order?.additional?.combo_discount_amount ? order?.additional?.combo_discount_amount : 0))
                            }
                        />
                    </td>
                </tr>
                {order.additional?.combo_discount_amount ? (
                    <tr>
                        <th>Combo Deals Savings</th>
                        <td>
                            {order.additional?.combo_discount_amount.toString().charAt(0) == "-" ? "- " : ""}
                            {order.additional?.combo_discount_amount.toString().charAt(0) == "-" ? (
                                <Currency value={parseFloat(order.additional?.combo_discount_amount.toString().substring(1)).toFixed(2)} />
                            ) : (
                                <Currency value={order.additional?.combo_discount_amount} />
                            )}
                        </td>
                    </tr>
                ) : null}
                {order.total_tax_amount && parseFloat(order.total_tax_amount) > 0 ? (
                    <tr>
                        <th>Tax Amount</th>
                        <td>
                            <Currency value={order.total_tax_amount} />
                        </td>
                    </tr>
                ) : null}

                {/* {order?.total_shipping_fee && order?.shipping_info?.type == 2 ? (
                    <tr>
                        <th>Shipping</th>
                        <td>
                            <Currency value={order.total_shipping_fee} />
                        </td>
                    </tr>
                ) : null} */}

                {/* {parseFloat(order?.total_free_shipping_fee) > 0 && order?.shipping_info?.type == 2 ? (
                    <tr>
                        <th>Free Shipping</th>
                        <td>
                            - <Currency value={order.total_free_shipping_fee} />
                        </td>
                    </tr>
                ) : null}

                {order?.additional?.standard_voucher_free_shipping_amount ? (
                    <tr>
                        <th>Shipping Voucher Discount</th>
                        <td>
                            - <Currency value={order?.additional?.standard_voucher_free_shipping_amount} />
                        </td>
                    </tr>
                ) : null}

                {(order.event_data_info?.vouchers && order.event_data_info?.vouchers[0]) || order.additional?.standard_voucher_amount ? (
                    <tr>
                        <th>
                            Voucher Discount
                            <br />
                            {order.event_data_info?.vouchers[0]?.code ? (
                                <span className="platform-applied-voucher">{order.event_data_info?.vouchers[0]?.code}</span>
                            ) : null}
                        </th>
                        <td>
                            {"- "}
                            <Currency
                                value={
                                    order.additional?.standard_voucher_amount
                                        ? order.additional?.standard_voucher_amount
                                        : order.voucher_amount
                                }
                            />
                        </td>
                    </tr>
                ) : null}

                {order.additional?.point_deduct_amount ? (
                    <tr>
                        <th>
                            <span>
                                <small className="text-danger">
                                    <b>(-{order.additional?.point_redeem_amount} points) </b>
                                </small>
                            </span>{" "}
                            Point Used
                            <br />
                        </th>
                        <td>
                            {"- "}
                            <Currency value={order.additional?.point_deduct_amount} />
                        </td>
                    </tr>
                ) : null} */}

                {order.adjust_amount ? (
                    <tr>
                        <th>Adjust Amount</th>
                        <td>
                            {order.adjust_amount.toString().charAt(0) == "-" ? "- " : ""}
                            {order.adjust_amount.toString().charAt(0) == "-" ? (
                                <Currency value={parseFloat(order.adjust_amount.toString().substring(1)).toFixed(2)} />
                            ) : (
                                <Currency value={order.adjust_amount} />
                            )}
                        </td>
                    </tr>
                ) : null}
            </tbody>
            <tfoot className="total-summary">
                <tr>
                    <th>Total</th>
                    <td className="total-price">
                        <Currency value={order.total_amount} />
                    </td>
                </tr>
            </tfoot>
        </table>
    ) : null;
}

export default OrderTable;
