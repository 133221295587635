import { getAccessToken } from "../api/auth";
import React, { useState, useEffect } from "react";
import { logout } from "../api/auth";
import { toast } from "react-toastify";
import moment from "moment";
import Currency from "../components/shared/Currency";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet-async";
import { getSEOSettings } from "../api/general";
import store from "../store";
import { isValidPhoneNumber } from "libphonenumber-js";

const cookies = new Cookies();
export const cookiesSetting = {
    secure: true,
    sameSite: "strict",
    // httpOnly: true,
};
let uuid = require("uuid");

export const url = {
    home: () => "/",

    category: (category) => `/category/${category.slug}`,

    brand: (brand) => `/brand/${brand.slug}`,

    product: (product) => `/product/${product.slug}`,

    addOnDeals: (product) => `/addon-deal/${product.slug}`,

    comboDeals: (product) => `/combo-deal/${product.slug}`,

    dynamicPage: (url) => `/page/${url}`,

    goProductList: () => `/category/all-products`,

    event: (slug) => `/event-products/` + slug,

    pwpFreeGift: (product) => `/pwp-free-gift/${product.slug}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

export function getAuthHeader() {
    let token = getAccessToken();
    let vid = cookies.get("cartvid");
    let requestHeader = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
    };
    if (vid) requestHeader.cartvid = vid;
    if (token) requestHeader.Authorization = "Bearer " + getAccessToken();

    return requestHeader;
}

export function checkVisitorID(type) {
    let setting = process.env.NODE_ENV == "production" ? cookiesSetting : {};

    // for cartvid, check and generate uuid if no cookies found
    if (type == "cart") {
        let hasCartVID = true;
        if (cookies.get("cartvid") == undefined) {
            hasCartVID = false;
            let vID = uuid.v4();
            var expiryDate = new Date();
            expiryDate.setFullYear(expiryDate.getFullYear() + 3);
            let settings = {
                path: "/",
                expires: expiryDate,
                ...setting,
            };
            if (process.env.REACT_APP_DOMAIN) settings.domain = process.env.REACT_APP_DOMAIN;

            cookies.set("cartvid", vID, settings);
        }
        return hasCartVID;
    } else {
        // for onesignal broadcastid, check and getUserid if no cookies found
        if (window.OneSignal) {
            window.OneSignal.getUserId(function (userId) {
                // console.log("OneSignal User ID:", userId);
                var expiryDate = new Date();
                expiryDate.setMonth(expiryDate.getMonth() + 36);

                let settings = {
                    path: "/",
                    expires: expiryDate,
                    ...setting,
                };
                if (process.env.REACT_APP_DOMAIN) settings.domain = process.env.REACT_APP_DOMAIN;
                cookies.set("broadcastid", userId, settings);
            });
        }
    }
}

export function getDefaultAvatar() {
    return "/images/avatars/defaultAvatar.jpg";
}

export function getNoImg(size) {
    if (size == "small") {
        return "/images/no_image_276px.png";
    } else return "/images/no_image.png";
}

export function makeTitle(slug) {
    let words = [];
    if (slug) {
        words = slug.split("-");

        for (let i = 0; i < words.length; i++) {
            let word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
    }

    return words ? words.join(" ") : slug;
}

export function checkUnauthorized(err, history) {
    if (err.http_code == 401 || err.http_code == 403) {
        for (const errKey in err.data?.errors) {
            if (errKey.split(".")[0] == "token") {
                if (getAccessToken()) {
                    toast.error("Sorry, your login session expired. Please try again.");
                    if (history) logout(history);
                    else logout();
                }
                break;
            }
        }
        return true;
    } else {
        return false;
    }
}

export function cleanFormData(obj) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj;
}

/* Append data into FormData instance */
export function appendFormData(data) {
    let formData = new FormData();
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }
    return formData;
}

export function paramBuilder(rawParam) {
    let param = "";
    for (let key in rawParam) {
        if (rawParam[key]) {
            let symbol = param == "" ? "?" : "&";
            param = param + symbol + key + "=" + rawParam[key];
        }
    }
    return param;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function allEqual(arr, type) {
    if (type == "price") {
        if (!Array.isArray(arr)) arr = [arr];
        arr = arr?.map((item) => {
            return parseFloat(item).toFixed(2);
        });
    }
    return new Set(arr).size == 1;
}

export function showGeneralError(err) {
    if (err && (err.data?.message || err.message)) {
        toast.error(err.message ? err.message : err.data?.message);
    } else if (err.data?.errors) {
        let errMsg = "";
        for (const errorKey in err.data.errors) {
            errMsg += err.data?.errors[errorKey].message;
        }
        toast.error(errMsg);
    } else toast.error("We have encountered an error processing your request. Please try again later.");
}

export const returnDiscountType = (typeID) => {
    return typeID == 10 ? "RM" : "%";
};

export function returnResponsiveImg(imageObj, size) {
    if (imageObj) {
        if (imageObj.responsive_url && imageObj.responsive_url.length > 0) {
            if (size == "smallest" && imageObj.responsive_url[imageObj.responsive_url.length - 1]) {
                return imageObj.responsive_url[imageObj.responsive_url.length - 1];
            } else if (imageObj.responsive_url.length > 1) {
                let middle = Math.round(imageObj.responsive_url.length / 2);
                return imageObj.responsive_url[middle];
            } else return imageObj.responsive_url[0];
        } else {
            return imageObj.url;
        }
    } else return getNoImg();
}

export const redirectTo404 = () => {
    if (window.location.pathname == "/goto-404") return null;
    else return (window.location.href = "/goto-404");
};

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    let array = Array.from(map, ([name, value]) => ({ name, value }));
    return array;
}

export function compareProductIsSame(item, item2) {
    return item.parent_hash == null && item.selected_variant_group.uuid == item2.selectedVariant.uuid;
}

export function sortByIsRequired(a, b) {
    if (a.is_required > b.is_required) {
        return -1;
    }
    if (a.is_required < b.is_required) {
        return 1;
    }
    return 0;
}

export const renderComboDealsCondition = (condition, type) => {
    if (condition.is_greater == 0) {
        return type == "title" ? (
            <h4>
                Buy {condition.qty} at <Currency value={parseFloat(condition.price_per_item) * parseInt(condition.qty)} />
            </h4>
        ) : (
            <>
                Buy {condition.qty} at <Currency value={parseFloat(condition.price_per_item) * parseInt(condition.qty)} />
            </>
        );
    } else {
        return type == "title" ? (
            <h4>
                Buy {condition.qty} and above for <Currency value={condition.price_per_item} /> each
            </h4>
        ) : (
            <>
                Buy {condition.qty} and above for <Currency value={condition.price_per_item} /> each
            </>
        );
    }
};

export function checkDateInRange(startDate, endDate) {
    if (startDate && endDate) {
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        return moment(now).isBetween(startDate, endDate);
    } else return true;
}

export function setWithExpiry(key, value, ttl) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

export function checkHvStock(product) {
    let numStock = 0;
    if (product.variant_choices) {
        product.variant_choices.forEach((item) => {
            numStock += item.stock_count;
        });
    }

    return numStock > 0 ? true : false;
}

export function checkProductInCart(cartArr, product, returnType) {
    let prodsInCart = cartArr.filter((item) => item.product.uuid == product.uuid);
    if (returnType == "qty") {
        return prodsInCart.reduce((totalQty, item) => totalQty + item.quantity, 0);
    } else {
        return prodsInCart.length == 1
            ? prodsInCart[0]
            : prodsInCart.find(
                  (item) => item.variantGroupID == (product.variantGroupID ? product.variantGroupID : product.variant_choices[0].uuid)
              );
    }
}

// move out sub prod to base layer, return item in arr
export function returnCartItemByItem(cartItems) {
    let cartItemsList = [];
    cartItems.forEach((item) => {
        cartItemsList.push(item);
        if (item.subProds) {
            item.subProds.forEach((subItem) => {
                // only push active cart items
                if (subItem.status == 10) cartItemsList.push(subItem);
            });
        }
    });
    return cartItemsList;
}

/*  
    page title is the title show on window tab,
    title is for meta title
    desc is for meta description
    keywords is for meta keywords
    imgPath is image for that page (optional)
*/
export function makeSEOtag(pageTitle, title, desc, keywords, imgPath) {
    let storeTitle = store.getState().generalInfo?.info?.title;
    return (
        <Helmet>
            <title>{pageTitle + ` — ${storeTitle}`}</title>
            <meta name="og:title" property="og:title" content={title} />
            <meta name="twitter:title" property="twitter:title" content={title} />
            <meta name="description" content={desc ? desc : getSEOSettings("meta_description")} />
            <meta name="og:description" property="og:description" content={desc ? desc : getSEOSettings("meta_description")} />
            <meta name="twitter:description" property="twitter:description" content={desc ? desc : getSEOSettings("meta_description")} />
            <meta name="keywords" content={keywords ? keywords : getSEOSettings("meta_keyword")} />
            {imgPath
                ? [
                      <meta key="og:image" name="og:image" property="og:image" content={imgPath} />,
                      <meta key="twitter:image" name="twitter:image" property="twitter:image" content={imgPath} />,
                      <meta key="twitter:card" name="twitter:card" property="twitter:card" content="summary_large_image" />,
                  ]
                : null}
        </Helmet>
    );
}

export function checkProductStock(product) {
    let numStock = 0;
    product.variant_choices?.forEach((item) => {
        numStock += item.stock_count;
    });
    return numStock;
}

export function doMobileValidation(number) {
    if (number) return isValidPhoneNumber("+" + number);
    return false;
}

export const renderPlatformDiscount = (selectedVoucherObj) => {
    return selectedVoucherObj?.discount_value ? (
        <span className="platform-applied-voucher">
            {returnDiscountType(selectedVoucherObj.discount_type) == "%"
                ? selectedVoucherObj.discount_value + "% Discount"
                : "RM " + selectedVoucherObj.discount_value + " Off"}
        </span>
    ) : null;
};

export const makeCheckoutProductParams = (cartItemsList) => {
    let passParams = {};
    for (let i = 0; i < cartItemsList.length; i++) {
        let item = cartItemsList[i];

        passParams["items[" + i + "][product_uuid]"] = item.product.uuid;
        passParams["items[" + i + "][variant_group_uuid]"] = item.selected_variant_group?.uuid;
        passParams["items[" + i + "][quantity]"] = item.quantity;

        if (item.selected_addons && item.selected_addons?.length > 0) {
            for (let n = 0; n < item.selected_addons?.length; n++) {
                passParams["items[" + i + "][addons][" + n + "][uuid]"] = item.selected_addons[n].uuid;
                passParams["items[" + i + "][addons][" + n + "][index]"] = item.selected_addons[n].index;
                passParams["items[" + i + "][addons][" + n + "][qty]"] = item.selected_addons[n].qty;
            }
        }
        // for event prod
        if (item.sub_event_info && item.parent_hash) {
            passParams["items[" + i + "][event_uuid]"] = item.sub_event_info?.uuid;
            passParams["items[" + i + "][parent_hash]"] = item.parent_hash;
        }
        // for cart gift event prod
        if (item.cart_gift_info && item.cart_gift_info?.uuid) {
            passParams["items[" + i + "][event_cart_gift_uuid]"] = item.cart_gift_info?.uuid;
        }
        // for reward product
        if (item.reward_prod_info && item.reward_prod_info?.uuid) {
            passParams["items[" + i + "][reward_prod_uuid]"] = item.reward_prod_info?.uuid;
        }
    }
    return passParams;
};

export function formatFileName(filename) {
    let finalname = "";
    let temp = filename.split("/");
    let nameOnly = temp[temp.length-1];
    if (nameOnly) {
        finalname = nameOnly.split("?")[0];
    } else {
        finalname = nameOnly;
    }
    return finalname
}

export function formatAddress(addressData, type) {
    let addressArr = addressData?.address1?.split(",");
    let address1 = ""; let finalAddress = ""
    let address2 = addressArr.pop();
    for (let i = 0; i < addressArr.length; i++) {
        address1 = address1.concat(addressArr[i]) + ","
    }
    if (type == "address1") {
        finalAddress = address1
    } else if (type == "address2") {
        finalAddress = address2 + ","
    } else {
        finalAddress =
            (addressData?.postcode ? addressData?.postcode : "") +
            (addressData?.city ? ", " + addressData?.city : "") +
            (addressData?.state ? ", " + addressData?.state : "") +
            (addressData?.country ? ", " + addressData?.country : "");
    }
    return finalAddress;
}