// react
import React from "react";

// third-party
import { connect } from "react-redux";
import { Modal } from "reactstrap";

// application
import Product from "./Product";
import RewardProduct from "./RewardProduct";
import { Cross20Svg } from "../../svg";
import { quickviewClose } from "../../store/quickview";
import { useHistory } from "react-router-dom";

function Quickview(props) {
    const { product, open, quickviewClose } = props;
    const history = useHistory();

    let productView;

    if (product !== null) {
        if (product.require_point) productView = <RewardProduct product={product} layout="quickview" history={history} />;
        else productView = <Product product={product} layout="quickview" history={history} />;
    }

    return (
        <Modal isOpen={open} toggle={quickviewClose} centered size="xl">
            <div className="quickview">
                <button className="quickview__close" type="button" onClick={quickviewClose}>
                    <Cross20Svg />
                </button>

                {productView}
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    open: state.quickview.open,
    product: state.quickview.product,
});

const mapDispatchToProps = {
    quickviewClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quickview);
