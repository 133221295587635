export default [
    {
        title: 'Home',
        url: '',
    },
    {
        title: 'Solutions',
        url: '',
    },
    {
        title: 'Products',
        url: '/shop/category-grid-3-columns-sidebar',
    },
    {
        title: 'Contact',
        url: '/blog/category-classic',
    },
];
