export const userTitleList = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
    { value: "other", label: "Other" },
];

export const addressTypeList = [
    { code: "0", name: "Shipping" },
    { code: "1", name: "Billing" },
];

export const SHIPPING_ADDRESS_TYPE = "0";
export const BILLING_ADDRESS_TYPE = "1";

//checkout shipping type
export const TYPE_PICKUP = "1";
export const TYPE_DELIVERY = "2";

export const groupOrderStatusList = [
    { code: "0", name: "To Pay" },
    { code: "0,140", name: "To Ship" },
    { code: "25", name: "To Receive" },
    { code: "30", name: "Completed" },
    { code: "130,100,120,110", name: "Refund/Cancel" },
];

export const voucherUsageTypeList = [
    { code: "0", name: "All" },
    { code: "1", name: "Member" },
    { code: "2", name: "Guest" },
];

export const eventTypeList = [
    { code: 10, name: "Discount" },
    { code: 15, name: "Shocking Sales" },
    { code: 20, name: "Voucher" },
    { code: 30, name: "PWP Combo" },
    { code: 40, name: "PWP Bundle" },
    { code: 50, name: "PWP Free Gift" },
];

//Event code
export const EVENT_DISCOUNT = "10";
export const EVENT_SHOCKING_SALES = "15";
export const EVENT_VOUCHER = "20";
export const EVENT_PWP_COMBO = "30";
export const EVENT_PWP_BUNDLE = "40";
export const EVENT_PWP_FREE_GIFT = "50";
export const EVENT_CART_GIFT = "60";

export const locationTypeList = [
    { code: 0, name: "country" },
    { code: 1, name: "state" },
    { code: 2, name: "postcode" },
];

//Payment status code
export const PENDING_PAYMENT = 0;
export const PAYMENT_PAID = 10;
export const PAYMENT_REJECTED = 20;
export const PAYMENT_CANCELLED = 30;

//Order status code
export const ORDER_PENDING = 0;
export const ORDER_PROCESSING = 10;
export const ORDER_WAITING_STOCK = 15;
export const ORDER_READY = 20;
export const ORDER_DELIVERING = 25;
export const ORDER_COMPLETED = 30;
export const ORDER_REQUEST_REFUND = 100;
export const ORDER_REQUEST_REFUND_CANCEL = 110;
export const ORDER_REFUNDED = 120;
export const ORDER_CANCELLED = 130;
export const ORDER_FAIL_PAYMENT = 140;

//standard voucher type code
export const VOUCHER_NORMAL = "0";
export const VOUCHER_BIRTHDAY = "1";
export const VOUCHER_SHIPPING = "2";

export const voucherTypeList = [
    { code: "0", name: "Normal" },
    { code: "2", name: "Shipping Fee" },
];

//Cart status code
export const CART_ACTIVE = "10";
export const CART_INACTIVE = "90";

export const dealerLocation = [
    { value: "MY", name: "Malaysia", currencies: "MYR" }, { value: "US", name: "Overseas", currencies: "USD" }
]

export const dealerBusinessType = [
    { value: 1, name: "Retailer" }, { value: 2, name: "Importer" }, { value: 3, name: "Wholesaler / Distributor" },
    { value: 4, name: "Exporter" }, { value: 5, name: "Contract" }, { value: 6, name: "Sourcing Agent" },
    { value: 7, name: "Online business" },
]

export const productInterestList = [
    { value: "1", name: "Dining Set" }, { value: "2", name: "Living Room Furniture" },
    { value: "3", name: "Bedroom Furniture" }, { value: "4", name: "Workstation Furniture" },
    { value: "5", name: "Kitchen Furniture" }, { value: "6", name: "Sofa" }, { value: "7", name: "Others" },
    { value: "8", name: "All Furniture" }, 
]