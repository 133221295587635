import { getAuthHeader, appendFormData, paramBuilder } from "../services/utils";
import { updateUser } from "../store/user";
import store from "../store";
import { currencyChange } from "../store/currency";
const axios = require("axios");

const updateProfileInfo = (res) => {
    store.dispatch(
        updateUser({
            ...res.data,
            title: res.data.title,
            uuid: res.data.uuid,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            fullName: res.data.full_name,
            image: res.data.profile_image,
            email: res.data.email,
            mobilePrefix: res.data.mobile_prefix,
            mobile: res.data.mobile_number,
            expiredDate: res.data.xeersoft_expiry_date ? res.data.xeersoft_expiry_date : null,
            oneSignalHashUuid: res.data.one_signal_info?.hashed_uuid,
        })
    );
};

const updateCurrencyInfo = (res) => {
    let currencyInfo = res.data?.dealer_info?.currency_info?.code;
    let productInfo = store.getState().generalInfo?.info?.product
    let currency = {};
    switch (currencyInfo) {
        case "USD":
            currency = { code: 'USD', symbol: 'US$', name: 'US Dollar', rate: productInfo?.usd_rate ? productInfo?.usd_rate : 1 }
            break;
        case "MYR":
            currency = { code: "MYR", symbol: "RM", name: "Malaysian Ringgit", rate: 1 }
            break;
        default:
            currency = { code: "MYR", symbol: "RM", name: "Malaysian Ringgit", rate: 1 }
            break;
    }
    store.dispatch(currencyChange(currency))
}

export async function getProfile() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile?appends=membership";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                updateProfileInfo(data);
                updateCurrencyInfo(data);
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function updateProfile(data) {
    let formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                updateProfileInfo(data);
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function changePassword(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/change-password?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getAddress(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function addAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function updateAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function deleteAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=DELETE", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function resendVerificationLink(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/email/resend", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getRewardPoints(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/reward-point" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getMyNotification(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/notification" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getStandardVouchers(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/standard-voucher" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getRewardProducts(params) {
    params.appends = "rewards-product";
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/reward-product" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}
