import { getShippingFeeInfo } from "../../api/general";
import { SHIPPINGINFO_CLOSE, SHIPPINGINFO_OPEN } from "./shippingInfoActionTypes";

export function shippingInfoOpenSuccess(info) {
    return {
        type: SHIPPINGINFO_OPEN,
        info: info,
    };
}

export function shippingInfoClose() {
    return {
        type: SHIPPINGINFO_CLOSE,
    };
}

export function shippingInfoOpen() {
    return (dispatch, getState) => {
        const shippingInfo = getState().shippingInfo?.info;

        if (!shippingInfo) {
            return new Promise((resolve) => {
                getShippingFeeInfo().then((res) => {
                    if (res.data) {
                        dispatch(shippingInfoOpenSuccess(res.data));
                    }
                    resolve();
                });
            });
        } else dispatch(shippingInfoOpenSuccess(shippingInfo));
    };
}
