export default [
    {
        id: 1,
        name: "My Account",
        url: "/account/profile",
    },
    // {
    //     id: 2,
    //     name: "Addresses",
    //     url: "/account/addresses",
    // },
    {
        id: 3,
        name: "Enquiry History",
        url: "/account/enquiry",
    },
    // {
    //     id: 6,
    //     name: "Membership",
    //     url: "/account/membership",
    // },
    // {
    //     id: 4,
    //     name: "My Vouchers",
    //     url: "/account/vouchers",
    // },
    {
        id: 7,
        name: "Catalogue Download",
        url: "/account/catalogue-download",
    },
    {
        id: 5,
        name: "Notifications",
        url: "/account/notifications",
    },
   ,
];
