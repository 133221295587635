// react
import React from "react";

function Point(props) {
    const { value } = props;
    const unit = "points";
    let point = parseFloat(value ? value : 0);
    return <React.Fragment>{`${point} ${unit}`}</React.Fragment>;
}

export default Point;
