// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import { redirectTo404, returnDiscountType } from "../../services/utils";
import moment from "moment";
import BlockLoader from "../blocks/BlockLoader";
import { getEventByCode } from "../../api/event";
import { voucherUsageTypeList } from "../../data/generalData";
import { getSEOSettings } from "../../api/general";

function SiteVoucherDetails(props) {
    const { match, history } = props;
    const code = match.params ? match.params.code : null;
    const [voucherDetails, setVoucherDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getEventByCode(code)
            .then((res) => {
                setVoucherDetails(res.data);
                setLoading(false);
            })
            .catch((err) => {
                if (err.http_code == 404) {
                    redirectTo404()
                }
            });
    }, []);

    function closeWindow() {
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
                window.opener = null;
                window.close();
            } else {
                window.open("", "_top");
                window.top.close();
            }
        } else if (navigator.userAgent.indexOf("Firefox") > 0) {
            window.location.href = "about:blank ";
        } else {
            window.opener = null;
            window.open("", "_self", "");
            window.close();
        }
    }

    if (loading) return <BlockLoader />;

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Voucher Details - ${getSEOSettings("store_title")}`}</title>
            </Helmet>
            {voucherDetails ? (
                <div className="voucher-info-wrapper no-gutters">
                    <div className="voucher-info col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="top-bg" />
                        <div className="voucher-box">
                            <div className="voucher">
                                <div className="line" />
                                <div className="left w-100">
                                    <div className="name">{voucherDetails.voucher_name}</div>
                                    <div className="discount">
                                        {returnDiscountType(voucherDetails.discount_type) == "%"
                                            ? parseFloat(voucherDetails.discount_value) +
                                              returnDiscountType(voucherDetails.discount_type) +
                                              " Discount"
                                            : "RM" +
                                              parseFloat(voucherDetails.discount_value) +
                                              " Off"}
                                    </div>
                                    {parseFloat(voucherDetails.min_spend) > 0 ? (
                                        <span className="label mr-2">
                                            {"Min. Spend RM"}
                                            {parseFloat(voucherDetails.min_spend)}
                                        </span>
                                    ) : null}
                                    {parseFloat(voucherDetails.max_discount_amt) > 0 ? (
                                        <span className="label">
                                            {"MAX CAP "}
                                            {returnDiscountType(voucherDetails.discount_type) == "%"
                                                ? " RM " + parseFloat(voucherDetails.max_discount_amt)
                                                : " " +
                                                  parseFloat(voucherDetails.max_discount_amt) +
                                                  "%"}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="mb-3">
                                <label>Voucher Name</label>
                                <div>{voucherDetails.voucher_name}</div>
                            </div>
                            <div className="mb-3">
                                <label>Voucher Description</label>
                                <div>{voucherDetails.voucher_desc}</div>
                            </div>
                            <div className="mb-3">
                                <label>Expiry Date</label>
                                <div>
                                    {voucherDetails.expiry_date
                                        ? moment(voucherDetails.expiry_date).format(
                                              "DD/MM/YYYY hh:mm a"
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label>Applicable Product</label>
                                <div>
                                    {voucherDetails.joined_product
                                        ? voucherDetails.joined_product.map((prod, index) => {
                                              return (
                                                  prod.name +
                                                  (index == voucherDetails.joined_product.length - 1
                                                      ? ""
                                                      : ", ")
                                              );
                                          })
                                        : "All"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label>Applicable User</label>
                                <div>{voucherDetails.usage_type?.name}</div>
                            </div>
                            {/* <div className="mb-3">
                                <label>Usage Limit</label>
                                <div>{voucherDetails.available.message}</div>
                            </div> */}
                        </div>
                        <div className="bottom-btn">
                            <button
                                className="btn btn-md btn-primary"
                                onClick={() => closeWindow()}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

export default SiteVoucherDetails;
