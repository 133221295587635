export default {
    name: "Syopex",
    fullName: "Syopex",
    companyName: "Syopex",
    url: "https://uat.syopex.com",
    color: "#FF9400",
    meta_title: "Syopex",
    meta_description: "",
    meta_keyword: "",
    author: {
        name: "Ant Internet Shd Bhd",
        url: "https://ant-internet.com/",
        registration_number: "1199944-V",
    },
    contacts: {
        address: "715 Fake Street, New York 10021 USA",
        email: "stroyka@example.com",
        phone: "(800) 060-0730",
    },
};
