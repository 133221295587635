// react
import React from "react";

// data stubs
import theme from "../../data/theme";
import { formatAddress } from "../../services/utils";

export default function FooterContacts(props) {
    const { storeInfo } = props;
    return (
        <div className="site-footer__widget footer-contacts">
            {/* <h5 className="footer-contacts__title">Contact Us</h5> */}

            <p>
                {storeInfo?.company_info?.name ?? theme.companyName}{" "}
                {storeInfo?.company_info?.reg_num ? "(" + storeInfo?.company_info?.reg_num + ")" : null}
            </p>
            <ul className="footer-contacts__contacts">
                {storeInfo?.company_info?.address1 ? (
                    <li >
                        <div>{formatAddress(storeInfo?.company_info, "address1")}</div>
                        <div>{formatAddress(storeInfo?.company_info, "address2")}</div>
                        <div>{formatAddress(storeInfo?.company_info, "")}</div>
                    </li>
                ) : null}
                <br />
                {storeInfo?.contact_info?.number1 ? (
                    <>
                        <b className="footer-contacts__text">Office contact number: </b>
                        <li>
                            Telephone : {storeInfo?.contact_info?.number1}
                        </li>
                    </>
                ) : null}

                {storeInfo?.contact_info?.number2 ? (
                    <li>
                        <a href={"tel:" + storeInfo?.contact_info?.number2}>{storeInfo?.contact_info?.number2}</a>
                    </li>
                ) : null}

                <br />
                <p>Email : <a href={"mailto:" + storeInfo?.contact_info?.email}>{storeInfo?.contact_info?.email}</a></p>
            </ul>
        </div>
    );
}
