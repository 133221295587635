// react
import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../services/utils";

export default function FooterAboutUs(props) {
    const { items, dynamicFooter } = props;

    const linksList = items.map((item, index) => (
        <li key={index} className="footer-links__item">
            <Link to={item.url} className="footer-links__link">
                {item.title}
            </Link>
        </li>
    ));

    const dynamicLinksList = dynamicFooter?.map((item) => (
        <li key={item.slug} className="footer-links__item">
            <Link to={url.dynamicPage(item.slug)} className="footer-links__link">
                {item.name}
            </Link>
        </li>
    ));

    return (
        <>
            <div className="site-footer__widget footer-about">
                {/* <h5 className="footer-about__title">About</h5> */}
                <Link to="/">
                    <img src="images/logos/logo_white.png" alt="logo" className="footer-logo" />
                </Link>

                {/* <div className="footer-about__text">
                    We source sustainable raw material as we believe in saving the earth for our future generations. <br />
                    We actively contribute to our society's education and peace.
                </div> */}
            </div>
            <div className="site-footer__widget footer-links">
                <ul className="footer-links__list">
                    {linksList}
                    {dynamicFooter ? <div className="pt-4">{dynamicLinksList}</div> : null}
                </ul>
            </div>
        </>
    );
}
