// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import { cartAddItem } from "../../store/cart";
import { Check9x7Svg } from "../../svg";
import { url, allEqual, renderComboDealsCondition, sortByIsRequired, showGeneralError } from "../../services/utils";
import { toast } from "react-toastify";
import { quickviewClose } from "../../store/quickview";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Point from "./Point";

class RewardProduct extends Component {
    constructor(props) {
        super(props);
        const { product } = props;

        this.state = {
            windowWidth: "",
            quantity: 1,
            activeImg: null,
            prodImgArr: cloneDeep(product.product_info.images),
            selectedVariantChoice: product.variant_group_info,
            openModel: false,
            modelList: [],
            loading: true,
            productURL: window.location.href,
            addOnPrice: 0,
            totalReview: 0,
            stock: product.variant_group_info?.stock_count,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    getPresetMessage = () => {
        const { product } = this.props;
        let presetMessage = "";
        presetMessage = "Hi, I would like to enquiry for this product.";
        presetMessage += `\r\nName : ${product.name}`;
        if (product.brand_info?.name) presetMessage += `\r\nBrand : ${product.brand_info?.name}`;
        presetMessage += `\r\nCategory : ${product.product_category_info?.name}`;
        presetMessage += `\r\n${window.location.href}`;
        return encodeURI(presetMessage);
    };

    handleOnHoverOption(variant, index) {
        if (index == 0 && variant.image) {
            this.setState({ activeImg: variant.image.url });
        } else this.setState({ activeImg: null });
    }

    hvStock(variantID, order) {
        const { product } = this.props;
        const { selectedVariant } = this.state;
        if (selectedVariant.length > 0 || order == 0) {
            let currentVariantOrder = order == 1 ? 0 : 1;
            let matchCombinations = product.variant_choices.find((item) => {
                return (
                    item.combinations[order]?.uuid == variantID &&
                    item.combinations[currentVariantOrder]?.uuid == selectedVariant[currentVariantOrder]
                );
            });
            if (matchCombinations) {
                if (matchCombinations.stock_count == 0) return false;
            }
            return true;
        } else {
            return true;
        }
    }

    countTotalStock() {
        const { product } = this.props;
        const { stock } = this.state;
        let numStock = 0;
        product.variant_choices?.forEach((item) => {
            numStock += item.stock_count;
        });
        if (stock !== numStock) {
            this.setState({
                stock: numStock,
                totalStock: numStock,
            });
        }
    }

    checkAddOnOptions(e) {
        // e.preventDefault();
        const { product } = this.props;
        let selectedAddOn = [];
        for (let i = 0; i < product.addon_info?.length; i++) {
            let options = product.addon_info[i].additional_json?.options;
            for (let n = 0; n < options?.length; n++) {
                if (document.getElementById(n + "|" + options[n].name)?.checked)
                    selectedAddOn.push({ uuid: product.addon_info[i].uuid, index: n, qty: 1 });
            }
        }
        return selectedAddOn;
    }

    handleAddOnChange = (e, price, isRequired) => {
        let finalAddOn = this.state.addOnPrice;
        if (e.target.checked) {
            finalAddOn += price;
        } else {
            finalAddOn -= price;
        }

        this.setState({
            addOnPrice: parseFloat(finalAddOn),
        });
    };

    checkAddOnRequired() {
        const { product } = this.props;
        let valid = true;
        let selectedAddons = this.checkAddOnOptions();
        let requiredAddon = product.addon_info.filter((item) => item.additional_json.is_required == 1);
        if (requiredAddon?.length > 0) {
            if (selectedAddons.length == 0) {
                valid = false;
            } else {
                requiredAddon.forEach((requiredItem) => {
                    if (selectedAddons.find((item) => item.uuid == requiredItem.uuid) == undefined) valid = false;
                });
            }
        }
        return valid;
    }

    handleAddOnChangeQuantity = (quantity) => {
        // this.setState({ quantity });
    };

    renderSubText = (isRequired, id) => {
        const { openThisOption } = this.state;
        return (
            <div className="product__option-subtext">
                <div>{isRequired == 1 ? "(Required)" : "(Optional)"}</div>{" "}
                {/* {!isRequired == 1 ? (
                    <div className="collapse-arrow">
                        {openThisOption !== id ? (
                            <i className="fas fa-angle-down" />
                        ) : (
                            <i className="fas fa-angle-up" />
                        )}
                    </div>
                ) : null} */}
            </div>
        );
    };

    renderAddOn(addOnList) {
        const { openThisOption } = this.state;
        return addOnList && addOnList.length > 0 ? (
            <div className="form-group product__option">
                {addOnList.sort(sortByIsRequired).map((item) => {
                    return (
                        <div key={item.uuid} className="mb-3">
                            <div className="product__option-label">
                                {item.name}
                                {this.renderSubText(item.additional_json.is_required, 0)}
                            </div>
                            {this.renderAddOnOption(item)}
                        </div>
                    );
                })}
            </div>
        ) : null;
    }

    renderAddOnOption(addOnInfo) {
        const { openThisOption } = this.state;
        if (addOnInfo.additional_json && addOnInfo.additional_json?.options?.length > 0) {
            return (
                <div className="form-group product__option">
                    {/* <Collapse isOpen={openThisOption == 0}> */}
                    <div className="input-radio-color">
                        <div className="input-radio-label__list">
                            {addOnInfo.additional_json.options.map((item, optIndex) => {
                                return (
                                    <div key={optIndex}>
                                        <div className="option-row">
                                            <label className="addon-checkbox-container d-flex align-items-center c-pointer">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <input
                                                            className="input-check__input"
                                                            id={optIndex + "|" + item.name}
                                                            type={"checkbox"}
                                                            name={addOnInfo.uuid}
                                                            defaultChecked={
                                                                addOnInfo.length == 1 && addOnInfo.additional_json.is_required == 1
                                                            }
                                                            disabled={addOnInfo.length == 1 && addOnInfo.additional_json.is_required == 1}
                                                            onClick={(e) =>
                                                                this.handleAddOnChange(
                                                                    e,
                                                                    parseFloat(item.extra_price),
                                                                    addOnInfo.additional_json.is_required
                                                                )
                                                            }
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon " />
                                                    </span>
                                                    <label>{item.name}</label>
                                                </div>
                                            </label>
                                            <div className="d-flex align-items-center">
                                                <div className="additional-price mr-2">
                                                    + <Currency value={item.extra_price} />
                                                </div>
                                                <div>
                                                    {/* <InputNumber
                                                        id="addon-quantity"
                                                        className="addon-qty-input"
                                                        size="sm"
                                                        min={1}
                                                        max={10}
                                                        value={item.qty ? item.qty : 1}
                                                        onChange={this.handleAddOnChangeQuantity}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* </Collapse> */}
                </div>
            );
        }
    }

    renderAddOnPirce() {
        const { addOnPrice } = this.state;

        return (
            <span className="product__addon-price">
                + <Currency value={addOnPrice} />
            </span>
        );
    }

    calcHighestDiscount() {
        const { product } = this.props;
        let highestDisc = 0;
        product.variant_choices
            .filter((item) => item.is_sales)
            .forEach((item) => {
                let percent = this.calcDiscount(item.offer_price, item.retail_price);
                if (parseInt(percent) > highestDisc) {
                    highestDisc = percent;
                }
            });

        return highestDisc;
    }

    calcDiscount(currentPrice, oriPrice) {
        let different = parseFloat(oriPrice) - parseFloat(currentPrice);
        if (different > 0) {
            let percent = (different / oriPrice) * 100;
            return parseFloat(percent).toFixed(0);
        } else {
            return 0;
        }
    }

    renderDiscountBadge(mode, displayPrice, oriPrice) {
        if (mode == "highest") return <span className="discount-badge">{this.calcHighestDiscount()}% off</span>;
        return <span className="discount-badge">{this.calcDiscount(displayPrice, oriPrice)}% off</span>;
    }

    renderPriceRange = () => {
        const { product } = this.props;

        return (
            <>
                <span className="product__new-price">
                    <Currency value={product.sales_price[0]} />
                    {!allEqual(product.sales_price, "price") &&
                    product.sales_price[product.sales_price.length - 1] &&
                    product.sales_price.length > 1 ? (
                        <>
                            {" - "}
                            <Currency value={product.sales_price[product.sales_price.length - 1]} />
                        </>
                    ) : null}
                </span>
                {product.is_sales &&
                parseFloat(product.sales_price[product.sales_price.length - 1]) < parseFloat(product.price[product.price.length - 1]) ? (
                    <>
                        <span className="product__old-price">
                            <Currency value={product.price[0]} />
                            {product.price[product.price.length - 1] && product.price.length > 1 ? (
                                <>
                                    {" - "}
                                    <Currency value={product.price[product.price.length - 1]} />
                                </>
                            ) : null}
                        </span>
                        {this.renderDiscountBadge("highest")}
                    </>
                ) : null}
            </>
        );
    };

    showAvailability = () => {
        const { stock } = this.state;

        return (
            "Availability: " + (stock == 0 ? "Sold Out" : stock <= 10 ? "Limited Stock" : stock > 100 ? "In Stock" : +stock + " in stock")
        );
    };

    render() {
        const { layout, cartAddItem, whastappNumber, history, user, eventData } = this.props;
        const { quantity, prodImgArr, activeImg, selectedVariantChoice, stock, totalStock, productURL, windowWidth, addOnPrice } =
            this.state;
        let prices;
        let product = this.props.product.product_info;
        prices = <Point value={this.props.product.require_point} />;
        // selectedVariant.length == product.variant_info.length && selectedVariantID ? (
        //     // when user has select variant, show specific price of the variant
        //     product.is_sales ? (
        //         <>
        //             <span className="product__new-price">
        //                 <Currency value={displayPrice} />
        //             </span>
        //             {!allEqual([displayPrice, oriPrice], "price") ? (
        //                 <>
        //                     <span className="product__old-price">
        //                         <Currency value={oriPrice} />
        //                     </span>
        //                     {this.renderDiscountBadge(null, displayPrice, oriPrice)}
        //                 </>
        //             ) : null}
        //         </>
        //     ) : (
        //         <Currency value={displayPrice} />
        //     )
        // ) : !allEqual(product.price, "price") ? (
        //     this.renderPriceRange()
        // ) : (
        //     <Currency value={product.price} />
        // );

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={prodImgArr} activeImg={activeImg} />

                    <div className="product__info">
                        {product.brand_info ? (
                            <div>
                                <Link
                                    to={url.brand(product.brand_info)}
                                    // onClick={() => this.handleRedirect()}
                                    className="brand_name large mb-1"
                                >
                                    {product.brand_info.name}
                                </Link>
                            </div>
                        ) : null}
                        <h3 className="product__name">
                            {product.name}
                            {selectedVariantChoice?.combinations.length == 1 && selectedVariantChoice?.combinations[0].name == "main"
                                ? null
                                : " - " +
                                  selectedVariantChoice?.combinations?.map((item, index) => {
                                      return item.name;
                                  })}
                        </h3>

                        {/* <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating
                                    readonly
                                    initialRating={parseInt(product.review_stats?.avg)}
                                    className="rating-star"
                                    emptySymbol={"far fa-star"}
                                    fullSymbol="fas fa-star"
                                />
                            </div>
                            <div className="product__rating-legend">{`${totalReview} Reviews`}</div>
                        </div> */}
                        <div className="product__description">{product.short_description}</div>
                    </div>

                    <div className="product__sidebar">
                        {product?.enquiry_only ? (
                            <div className="mb-3" />
                        ) : (
                            <div className="product__prices">
                                {prices}
                                {addOnPrice > 0 && product.addon_info?.length > 0 ? this.renderAddOnPirce() : null}
                            </div>
                        )}

                        {eventData?.event_type?.name == "PWP Combo" ? (
                            <label className="outline-label mb-3">
                                {renderComboDealsCondition(eventData?.event_info?.condition_json[0])}
                            </label>
                        ) : eventData?.event_type?.name == "PWP Bundle" ? (
                            <label className="outline-label mb-3">Add On deals</label>
                        ) : null}

                        <form className="product__options">
                            <div className="form-group product__option">
                                {product.additional?.is_pre_order && product.additional?.is_pre_order == 1 ? (
                                    <div className="text-danger mb-2" style={{ fontSize: 15 }}>
                                        *Pre Order (estimate delivery on{" "}
                                        {moment(product.additional?.estimated_delivery_date).format("DD/MM/YYYY")})
                                    </div>
                                ) : null}

                                {(product.progression !== null && product.progression !== "ongoing") ||
                                product.is_exclusive_dealer ? null : (
                                    <div className="product__availability mb-2">{this.showAvailability()}</div>
                                )}
                                <div className="product__actions">
                                    {parseFloat(user?.total_point) < this.props.product.require_point ? (
                                        <p className="px-1 py-3 h5 text-muted">Not enough point to redeem this item</p>
                                    ) : product.is_exclusive_dealer ? (
                                        <span className={"exclusive-outline-label"}>Exclusive to dealer only</span>
                                    ) : product.progression == "upcoming" ? (
                                        <div className={"btn btn-disabled " + (windowWidth < 576 ? "btn-md" : "btn-lg")}>Coming Soon</div>
                                    ) : product.progression == "history" ? (
                                        <div className={"btn btn-disabled " + (windowWidth < 576 ? "btn-md" : "btn-lg")}>Not Available</div>
                                    ) : product?.enquiry_only || stock == 0 ? (
                                        <div className="product__actions-item product__actions-item--addtocart">
                                            <a
                                                target="_blank"
                                                href={
                                                    "https://api.whatsapp.com/send?phone=" +
                                                    whastappNumber +
                                                    "/&text=" +
                                                    this.getPresetMessage()
                                                }
                                                className={"btn btn-primary " + (windowWidth < 576 ? "btn-md" : "btn-lg")}
                                            >
                                                WhatsApp for Enquiry
                                            </a>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="product__actions-item">
                                                <InputNumber
                                                    id="product-quantity"
                                                    aria-label="Quantity"
                                                    className="product__quantity"
                                                    size={windowWidth < 576 ? "md" : "lg"}
                                                    min={1}
                                                    max={stock}
                                                    value={quantity}
                                                    onChange={this.handleChangeQuantity}
                                                />
                                            </div>

                                            <div className="product__actions-item product__actions-item--addtocart">
                                                <AsyncAction
                                                    action={() =>
                                                        cartAddItem(
                                                            product,
                                                            selectedVariantChoice.uuid,
                                                            this.checkAddOnOptions(),
                                                            quantity,
                                                            { rewardUUID: this.props.product.uuid },
                                                            history
                                                        )
                                                            .then((err) => {
                                                                if (layout == "quickview") quickviewClose();
                                                            })
                                                            .catch((err) => {
                                                                let errMsg = "";
                                                                for (const errorKey in err.errors) {
                                                                    errMsg += err?.errors[errorKey].message;
                                                                }
                                                                toast.error(errMsg);
                                                            })
                                                    }
                                                    render={({ run, loading }) => (
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            disabled={!quantity}
                                                            className={classNames(
                                                                "btn btn-primary " + (windowWidth < 576 ? "btn-md" : "btn-lg"),
                                                                {
                                                                    "btn-loading": loading,
                                                                }
                                                            )}
                                                        >
                                                            Add to Cart
                                                        </button>
                                                    )}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        {/* <div className="product__tags tags">
                            <div className="tags__list">
                                <Link to="/">Mounts</Link>
                                <Link to="/">Electrodes</Link>
                                <Link to="/">Chainsaws</Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

RewardProduct.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

RewardProduct.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    user: state.user?.profile,
});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(() => mapStateToProps, mapDispatchToProps)(RewardProduct);
