// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";

// application
import { ArrowRoundedUp13x8Svg } from "../../svg";

export default function Fab(props) {
    const [show, setShow] = useState(false);
    const { whatsappNumber } = props;
    const showFrom = 300;
    const classes = classNames("totop_btn_container", {
        "totop_btn_container--show": show,
    });
    const btnClasses = classNames("whatsapp__button", {
        "whatsapp__button--up": show,
    });

    const onClick = () => {
        try {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } catch {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        let state = false;
        const onScroll = () => {
            const newState = window.pageYOffset >= showFrom;

            if (state !== newState) {
                setShow((state = newState));
            }
        };

        window.addEventListener("scroll", onScroll, { passive: true });

        return () => window.removeEventListener("scroll", onScroll, { passive: true });
    }, [setShow]);

    return (
        <div className="totop">
            <div className="totop__body">
                <div className="totop__start" />
                <div className="totop__container container" />
                <div className="totop__end">
                    <div>
                        {whatsappNumber ? (
                            <a
                                target="_blank"
                                href={"https://api.whatsapp.com/send?phone=" + whatsappNumber}
                                className={btnClasses}>
                                <i className="fab fa-whatsapp" />
                            </a>
                        ) : null}

                        <div className={classes}>
                            <button type="button" className="totop__button" onClick={onClick}>
                                <ArrowRoundedUp13x8Svg />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
